import React from 'react';
import "./Error.css";

function Error() {
  return (
    <section className='error-page'>
      <div className='container'>
        <h2>404</h2>
      </div>
    </section>
  )
}
export default Error;