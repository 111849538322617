import React from 'react';
import "./Hero.css";

function Hero() {
    return (
        <section className='hero'>
            <div className='container'>
                <div className='slogan'>
                    <img src='assets/slogan.svg' alt='slogan icon'/>
                    <span>we are here for you</span>
                    <img src='assets/slogan.svg' alt='slogan icon'/>
                </div>
                <div className='content'>
                    <h1>Empowering your brand with <span>netraven</span> in the digital universe.</h1>
                    <p>Welcome to Netraven, where digital innovation
                    meets strategic excellence as a dynamic force
                    in the realm of Software Development, we are
                    dedicated tourat propelling businesses into
                    the spotlight of online success.</p>
                </div>
                <div className='btns'>
                    <a href='#services'>Our Services</a>
                    <a href='#contactUs'>Contact Us</a>
                </div>
            </div>
        </section>
    )
}
export default Hero;