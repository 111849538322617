import React from 'react';
import "./PrivacyPolicy.css";
function PrivacyPolicy() {
    return (
        <section className='privacy-policy'>
            <div className='container'>
                <div className='content'>
                    <div className='content-holder'>
                        <h3>Privacy Policy</h3>
                        <p>Privacy Policy At Netraven, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website, software, or services.</p>
                    </div>
                    <ul>
                        <li>
                            <h4>Information We Collect</h4>
                            <p>When you use our website or services, we may collect certain information about you, such as your name, email address, contact information, and payment details. We may also collect information about your usage of our website and services, such as your IP address, browser type, and device information.</p>
                        </li>
                        <li>
                            <h4>How We Use Your Information</h4>
                            <p>We may use the information we collect from you to provide, maintain, and improve our website and services, to communicate with you, to process your payments, and to personalize your experience. We may also use your information to analyze trends, administer our website, and gather demographic information.</p>
                        </li>
                        <li>
                            <h4>Disclosure of Your Information</h4>
                            <p>We may disclose your information to third parties who provide services on our behalf, such as payment processing, data analysis, and customer support. We may also disclose your information to comply with applicable laws and regulations, to respond to a subpoena, search warrant, or other lawful request for information we receive, or to protect our rights.</p>
                        </li>
                        <li>
                            <h4>Data Security</h4>
                            <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee its absolute security.</p>
                        </li>
                        <li>
                            <h4>Your Choices</h4>
                            <p>You have the right to access, correct, or delete your personal information. You may also choose to opt-out of certain communications from us. If you would like to do so.</p>
                        </li>
                        <li>
                            <h4>Children's Privacy</h4>
                            <p>Our website and services are not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If we learn that we have collected personal information from a child under the age of 13, we will take appropriate steps to delete that information.</p>
                        </li>
                        <li>
                            <h4>Changes to This Privacy Policy</h4>
                            <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</p>
                        </li>
                        <li>
                            <h4>Contact Us</h4>
                            <p> If you have any questions about this Privacy Policy, please contact us at <a href='mailto:info@netraven.org'>info@netraven.org</a>.</p>
                        </li>
                        <li>
                            <p>By using our website or services, you consent to our Privacy Policy and agree to its terms. Thank you for choosing Netraven!</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default PrivacyPolicy;