import Home from "./Pages/Home/Home";
import Header from "./Layouts/Header/Header"
import Footer from "./Layouts/Footer/Footer"
import Error from "./Pages/Error/Error"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="*" element={<Error />} exact />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} exact />
          <Route path="privacy-policy" element={<PrivacyPolicy />} exact />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;