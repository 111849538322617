import React from 'react';
import "./Home.css";
import Hero from '../../Components/Hero/Hero';
import Marquee from '../../Widgets/Marquee/Marquee';
import Testimonials from '../../Components/Testimonials/Testimonials';
import AboutUs from '../../Components/AboutUs/AboutUs';
import ContactUs from '../../Components/ContactUs/ContactUs';
import Services from '../../Components/Services/Services';
import Technologies from '../../Components/Technologies/Technologies';
import Clients from '../../Components/Clients/Clients';
import Tools from '../../Components/Tools/Tools';
import Features from '../../Components/Features/Features';
import Counter from '../../Components/Counter/Counter';

function Home() {
    return (
        <section className='home'>
            <Hero />
            <Marquee />
            <AboutUs />
            <Services />
            <Features />
            <Clients />
            <Technologies />
            <Tools />
            <Counter />
            <Testimonials />
            <ContactUs />
        </section>
    )
}
export default Home;