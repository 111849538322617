import React from 'react';
import "./Header.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Header() {
    // Handle Burger Icon
    const handleBurgerIcon = () => {
        let menu = document.querySelector(".menu");
        menu.classList.toggle("active");
    }
    return (
        <header>
            <div className='container'>
                <a href='/'>
                    <img src='assets/logo.svg' className='logo' title="Logo" alt='Logo'/>
                </a>
                <ul className='menu'>
                    <li><Link to='/'>Home</Link></li>
                    <li><a href='#aboutUs'>About Us</a></li>
                    <li><a href='#services'>Services</a></li>
                    <li><a href='#portfolio'>Portfolio</a></li>
                    <li><a href='#clients'>Clients</a></li>
                    <li><a href='#technologies'>Technologies</a></li>
                    <li><a href='#testimonials'>Testimonials</a></li>
                    <li><a href='#contactUs'>Contact Us</a></li>
                </ul>
                <a href='#contactUs' className='started-btn'>Get Started</a>
                <FontAwesomeIcon className='menu-icon' onClick={handleBurgerIcon} icon={faBars }/>
            </div>
        </header>
    )
}
export default Header;