import React from 'react';
import "./Features.css";
import Heading from '../../Widgets/Heading/Heading';

function Features() {
    return (
        <section className='features' id='features'>
            <Heading slogan="Features" heading="Our Features" description="What makes us unique"/>
            <div className='container'>
                <div className='box'>
                    <img src='assets/features/support.svg' alt=''/>
                    <h4>24/7 Support</h4>
                    <p>Get help whenever you need it with our dedicated support team.</p>
                </div>
                <div className='box'>
                    <img src='assets/features/agile.svg' alt=''/>
                    <h4>Agile Methodology</h4>
                    <p>Iterative development for faster project completion and client feedback integration.</p>
                </div>
                <div className='box'>
                    <img src='assets/features/rates.svg' alt=''/>
                    <h4>Competitive Rates</h4>
                    <p>Get high-quality services without breaking the bank.</p>
                </div>
            </div>
        </section>
    )
}
export default Features;