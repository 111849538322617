import React from 'react';
import "./Clients.css";
import Heading from "../../Widgets/Heading/Heading";
import Marquee from 'react-fast-marquee';

function Clients() {
    return (
        <section className='clients' id='clients'>
            <Heading
                slogan="our partners in success"
                heading="Our Clients"
                description="We've partnered with companies big and small to design the best user experiences."
            />
            <div className='container'>
                <Marquee speed="40">
                    <div className='holder'>
                        <img src='assets/clients/aga.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/anty.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/car.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/clara.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/e-go.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/excape.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/fitdose.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/kenda.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/luxxa.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/monhna.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/mundotrave.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/nfm.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/plank.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/rawah.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/sana.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/science-street.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/sif-care.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/stem-zone.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/teslmedak.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/trend-social.svg' alt=''/>
                    </div>
                    <div className='holder'>
                        <img src='assets/clients/wizfreelance.svg' alt=''/>
                    </div>
                    
                
                </Marquee>
            </div>
        </section>
    )
}
export default Clients;