import React from 'react';
import "./Tools.css";
import Heading from "../../Widgets/Heading/Heading"

function Tools() {
    return (
        <section className='tools' id='tools'>
                <Heading 
                    slogan="tools THAT WE use"
                    heading="Our Tools"
                    description="The tools that we use to achieve our client goal"
                />
                <div className='container'>
                    <div className='box'>
                        <img src='assets/tools/figma.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/after-effects.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/illustrator-.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/premiere.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/xd.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/audition.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/indesign.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/lightroom.svg' alt=''/>
                    </div>
                    <div className='box'>
                        <img src='assets/tools/photoshop.svg' alt=''/>
                    </div>
                </div>
            </section>
    )
}
export default Tools;