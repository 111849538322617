import React from 'react';
import "./ContactUs.css";
import Heading from "../../Widgets/Heading/Heading"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {faPhone, faEnvelope, faLocationDot} from "@fortawesome/free-solid-svg-icons"
function ContactUs() {
    return (
        <section className='contact-us' id='contactUs'>
            <div className='container'>
                <div className='info'>
                    <Heading 
                    slogan="Let's Talk"
                    heading="Explore Our Solutions"
                    description="We’re happy to answer any questions you may have and we help you determine which of our services best fit your needs."
                    />
                    <div className='call-mail'>
                        <div className='holder'>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <div>
                                <span>Location</span>
                                <a href='https://www.google.com/maps/place/Zamalek,+Cairo+Governorate/@30.0559053,31.2011716,14z/data=!3m1!4b1!4m6!3m5!1s0x145840e059d98225:0x91cb6f582e8b215c!8m2!3d30.0609422!4d31.219709!16s%2Fm%2F03qpf39?entry=ttu'>Cairo, Egypt</a>
                            </div>
                        </div>
                        <div className='holder'>
                            <FontAwesomeIcon icon={faPhone} />
                            <div>
                                <span>To More Inquiry</span>
                                <a href='tel:+201157130586'>+20 1157130586</a>
                            </div>
                        </div>
                        <div className='holder'>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <div>
                                <span>To Send Mail</span>
                                <a href='mailto:info@netraven.org'>info@netraven.org</a>
                            </div>
                        </div>
                    </div>
                    <ul className='social'>
                        <li><a href='https://www.facebook.com/profile.php?id=100090282973828'><FontAwesomeIcon icon={faFacebookF} /></a></li>
                        <li><a href='https://www.linkedin.com/company/netraven'><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                        <li><a href='https://www.instagram.com/netraven23/'><FontAwesomeIcon icon={faInstagram} /></a></li>
                        <li><a href='https://wa.me/+201157130586'><FontAwesomeIcon icon={faWhatsapp} /></a></li>
                    </ul>
                </div>
                <form>
                    <h3>Your Success Starts Here!</h3>
                    <div className='input-holder'>
                        <input type='text' placeholder='Full Name' required/>
                    </div>
                    <div className='input-holder'>
                        <input type='text' placeholder='Phone Number' required/>
                    </div>
                    <div className='input-holder'>
                        <input type='email' placeholder='Email' required/>
                    </div>
                    <button type='submit'>Send</button>
                </form>
            </div>
        </section>
    )
}
export default ContactUs;