import React from 'react';
import "./Counter.css";

function Counter() {
    return (
        <section className='counter'>
            <div className='container'>
                <div className='box'>
                    <div className='image-holder'>
                        <img src='assets/counter/experience.svg' alt=''/>
                    </div>
                    <div>
                        <h3>+2</h3>
                        <p>Years on the Market</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='image-holder'>
                        <img src='assets/counter/client.svg' alt=''/>
                    </div>
                    <div>
                        <h3>+110 </h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='image-holder'>
                        <img src='assets/counter/projects.svg' alt=''/>
                    </div>
                    <div>
                        <h3>+250 </h3>
                        <p>Projects Delivered</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Counter;