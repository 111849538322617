import React from 'react';
import "./Technologies.css";
import Heading from "../../Widgets/Heading/Heading"
function Technologies() {
    return (
        <section className='technologies' id='technologies'>
            <Heading 
                slogan="technologies THAT WE use"
                heading="Our Technologies"
                description="The technologies that we use to achieve our client goal"
            />
            <div className='container'>
                <div className='box'>
                    <img src='assets/technologies/html.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/css.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/javascript.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/bootstrap.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/tailwindcss.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/scss.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/less.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/react.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/redux.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/nextjs.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/remix.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/angular.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/ionic.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/vue-js.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/nuxt.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/typescript.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/python.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/DJ.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/node-js.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/EX.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/nest-js.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/php.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/laravel.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/ruby.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/c.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/c++.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/C-SHARP.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/ASP.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/flutter.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/dart.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/swift.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/java.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/kotlin.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/golang.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/mongo-db.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/sql.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/mysql.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/wordpress.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/shopify.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/salla.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/zid.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/webflow.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/open-cart.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/wuilt.svg' alt=''/>
                </div>
                <div className='box'>
                    <img src='assets/technologies/drupal.svg' alt=''/>
                </div>
            </div>
        </section>
    )
}
export default Technologies;