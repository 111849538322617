import React from 'react';
import "./Services.css";
import Heading from "../../Widgets/Heading/Heading";

function Services() {
    return (
        <section className='services' id='services'>
            <Heading 
                slogan="services that we offer"
                heading="Our Services"
                description="Discover a suite of tailored solutions designed to meet your unique needs." />
            <div className='container'>
                <div className='serv'>
                    <img src="assets/services/web.svg" alt=""/>
                    <h3>Web Development</h3>
                    <p>Craft stunning websites & web apps. We bring your vision to life, from concept to user-friendly reality.</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/mobile.svg" alt=""/>
                    <h3>Mobile Development</h3>
                    <p>Build cutting-edge mobile apps for iOS & Android. We deliver intuitive experiences that drive engagement. .</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/ecommerce-solutions.svg" alt=""/>
                    <h3>E-commerce Solutions</h3>
                    <p>Streamline your online store. We create user-friendly e-commerce experiences that boost sales.</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/software.svg" alt=""/>
                    <h3>Software Development</h3>
                    <p>Tailor-made software to solve your unique needs. We design & develop powerful applications to fit your business.</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/ai.svg" alt=""/>
                    <h3>Artificial Intelligence</h3>
                    <p>Infuse intelligence into your software. We build AI-powered solutions for enhanced efficiency & decision making.</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/testing.svg" alt=""/>
                    <h3>QA & QC Testing</h3>
                    <p>Deliver flawless software. Our rigorous QA & QC testing ensures a bug-free, high-quality product.</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/ui-ux.svg" alt=""/>
                    <h3>UI & UX Design</h3>
                    <p>Design experiences users love. We craft beautiful, intuitive interfaces that drive engagement.</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/graphic.svg" alt=""/>
                    <h3>Graphic Design</h3>
                    <p>Eye-catching visuals for your brand. We create impactful graphics that elevate your message.</p>
                </div>
                <div className='serv'>
                    <img src="assets/services/consulting.svg" alt=""/>
                    <h3>Consulting</h3>
                    <p>Navigate your software journey. We provide expert consulting to help you build & launch successful software.</p>
                </div>
            </div>
        </section>
    )
}
export default Services;