import React from 'react';
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; 
function Footer() {
    return (
        <footer>
            <div className='container'>
                <div className='data-holder'>
                    <Link to='/'>
                        <img src='assets/logo.svg' alt=''/>
                    </Link>
                    <p>Welcome to Netraven, where digital innovation meets strategic excellence as a dynamic force in the realm of Software Development, we are dedicated tourat propelling businesses into the spotlight of online success.</p>
                    <ul className='social'>
                        <li><a href='https://www.facebook.com/profile.php?id=100090282973828'><FontAwesomeIcon icon={faFacebookF} /></a></li>
                        <li><a href='https://www.linkedin.com/company/netraven'><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                        <li><a href='https://www.instagram.com/netraven23/'><FontAwesomeIcon icon={faInstagram} /></a></li>
                        <li><a href='https://wa.me/+201157130586'><FontAwesomeIcon icon={faWhatsapp} /></a></li>
                    </ul>
                </div>
                <div>
                    <h2>Company</h2>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><a href='#aboutUs'>About Us</a></li>
                        <li><a href='#services'>Services</a></li>
                        <li><a href='#features'>Features</a></li>
                        <li><a href='#portfolio'>Portfolio</a></li>
                        <li><a href='#clients'>Clients</a></li>
                        <li><a href='#technologies'>Technologies</a></li>
                        <li><a href='#testimonials'>Testimonials</a></li>
                        <li><a href='#contactUs'>Contact Us</a></li>
                        <li><a href='/'>Careers</a></li>
                    </ul>    
                </div>
                <div>
                    <h2>Services</h2>
                    <ul>
                        <li><a href='/'>Web Development</a></li>
                        <li><a href='/'>Mobile Development</a></li>
                        <li><a href='/'>E-commerce Solutions</a></li>
                        <li><a href='/'>Software Development</a></li>
                        <li><a href='/'>Artificial Intelligence</a></li>
                        <li><a href='/'>QA & QC Testing</a></li>
                        <li><a href='/'>UI & UX Designing</a></li>
                        <li><a href='/'>Graphic Designing</a></li>
                        <li><a href='/'>Consulting</a></li>
                    </ul>    
                </div>
            </div>
            <div className='container copyright-container-holder'>
                <p className='copyright'>&#169; <Link to='/'>Netraven</Link> 2022 - {new Date().getFullYear()} | All rights reserved</p>
                <ul>
                    <li><Link to='terms-and-conditions'>Terms & Conditions</Link></li>
                    <li><Link to='privacy-policy'>Privacy Policy</Link></li>
                </ul>
            </div>
        </footer>
    )
}
export default Footer;