import React from 'react';
import "./TermsAndConditions.css";
function TermsAndConditions() {
    return (
        <section className='term-and-conditions'>
            <div className='container'>
                <div className='content'>
                    <div className='content-holder'>
                        <h3>Terms and Conditions</h3>
                        <p>Welcome to Netraven! These Terms and Conditions govern your use of our website, software, and services. By accessing or using our website, software, or services, you agree to be bound by these Terms and Conditions. Please read them carefully before using our website, software, or services.</p>
                    </div>
                    <ul>
                        <li>
                            <h4>Acceptance of Terms</h4>
                            <p>By using our website, software, or services, you agree to these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you must not use our website, software, or services.</p>
                        </li>
                        <li>
                            <h4>Use of Our Services</h4>
                            <p> Our services include software development, design, consulting, and other related services. You agree to use our services only for lawful purposes and in accordance with these Terms and Conditions.</p>
                        </li>
                        <li>
                            <h4>Intellectual Property</h4>
                            <p>All content included on our website and software, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Netraven or its content suppliers and is protected by copyright laws.</p>
                        </li>
                        <li>
                            <h4>User Content</h4>
                            <p>You may be able to submit content, such as comments or reviews, on our website or software. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>
                        </li>
                        <li>
                            <h4>Privacy Policy</h4>
                            <p>Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using our website, software, or services, you agree to our Privacy Policy.</p>
                        </li>
                        <li>
                            <h4>Payment Terms</h4>
                            <p>If you purchase any services from us, you agree to pay all fees and charges incurred in connection with your purchase, including any applicable taxes. Payment must be made in advance unless otherwise agreed upon in writing.</p>
                        </li>
                        <li>
                            <h4>Disclaimer of Warranties</h4>
                            <p>Our website, software, and services are provided on an "as is" and "as available" basis without any warranties of any kind, whether express or implied. We do not warrant that our website, software, or services will be uninterrupted, secure, or error-free.</p>
                        </li>
                        <li>
                            <h4>Limitation of Liability</h4>
                            <p>To the fullest extent permitted by law, we will not be liable for any indirect, incidental, consequential, special, punitive, or exemplary damages arising out of or in connection with your use of our website, software, or services.</p>
                        </li>
                        <li>
                            <h4>Indemnification</h4>
                            <p>You agree to indemnify, defend, and hold harmless Netraven and its affiliates, officers, directors, employees, agents, licensors, and suppliers from and against all claims, losses, expenses, damages, and costs, including reasonable attorneys' fees, arising out of or in connection with your use of our website, software, or services or your violation of these Terms and Conditions.</p>
                        </li>
                        <li>
                            <h4>Governing Law</h4>
                            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Egypt, without giving effect to any choice or conflict of law provision or rule.</p>
                        </li>
                        <li>
                            <h4>Changes to Terms and Conditions</h4>
                            <p>We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of our website, software, or services after any such changes constitutes your acceptance of the new Terms and Conditions.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default TermsAndConditions;