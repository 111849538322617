import React from 'react';
import "./Testimonials.css";
import Heading from '../../Widgets/Heading/Heading';


function Testimonials() {
    return (
        <section className='testimonials' id='testimonials'>
            <Heading 
                slogan='clients feedback'
                heading='Happy Client Stories'
                description='These voices echo the confidence and satisfaction of clients who have witnessed firsthand the impact of our solutions.' 
            />
            <div className='container'>
                <div className='testimonial'>
                    <div className='client-data'>
                        <img src='assets/persons/adam.jpg' className='client-img' alt='client name'/>
                        <div className='name-position'>
                            <h3 className='name'>Abdelrahman Adam</h3>
                            <span className='position'>Clara CEO</span>
                        </div>
                    </div>
                    <p className='feedback'>
                        “I express my heartfelt thanks to Netraven for their exceptional work on the website. 
                        Truthfully, The website is incredible! This is far beyond my expectations, but their 
                        prompt completion pleasantly surprised me ❤️”
                    </p>
                </div>
                <div className='testimonial'>
                    <div className='client-data'>
                        <img src='assets/persons/mostafa.jpg' className='client-img' alt='client name'/>
                        <div className='name-position'>
                            <h3 className='name'>Mostafa Abouzaid</h3>
                            <span className='position'>Excape CEO</span>
                        </div>
                    </div>
                    <p className='feedback'>
                        “My deepest appreciation goes to Netraven for their remarkable dedication to the website.
                        Truly, the site is phenomenal! It surpasses my expectations, and their swift completion 
                        pleasantly astounded me”
                    </p>
                </div>
            </div>
        </section>
    )
}
export default Testimonials;