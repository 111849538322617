import React from 'react';
import "./Heading.css";
function Heading(props) {
    return (
        <div className='main-heading'>
            <div className='slogan'>
                <img src='assets/slogan.svg' alt='slogan icon'/>
                <span>{props.slogan}</span>
                <img src='assets/slogan.svg' alt='slogan icon'/>
            </div>
            <div className='heading'>
                <h2>{props.heading}</h2>
                <p>{props.description}</p>
            </div>
        </div>
    )
}
export default Heading