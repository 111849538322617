import React from 'react';
import "./Marquee.css";
import Marquee from 'react-fast-marquee';

const MyMarquee = () => {
    return (
        <section className='marquee'>
            <Marquee>
                <div className='holder'>
                    <img src='assets/bar-icon.svg' alt=''/>
                    <h3>WELCOME TO NETRAVEN SOLUTIONS</h3>
                </div>
                <div className='holder'>
                    <img src='assets/bar-icon.svg' alt=''/>
                    <h3>WE THRIVE ON CREATIVITY</h3>
                </div>
                <div className='holder'>
                    <img src='assets/bar-icon.svg' alt=''/>
                    <h3>YOUR SATISFACTION IS OUR PRIORITY</h3>
                </div>
                <div className='holder'>
                    <img src='assets/bar-icon.svg' alt=''/>
                    <h3>WE BELIEVE IN THE POWER OF COLLABORATION</h3>
                </div>
                <div className='holder'>
                    <img src='assets/bar-icon.svg' alt=''/>
                    <h3>WE ARE HERE FOR YOU</h3>
                </div>
                <div className='holder'>
                    <img src='assets/bar-icon.svg' alt=''/>
                    <h3>YOUR SUCCESS IS OUR CODE</h3>
                </div>
                
            </Marquee>
        </section>
    );
};

export default MyMarquee;
