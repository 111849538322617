import React from 'react';
import "./AboutUs.css";

function AboutUs() {
    return (
        <section className='about-us' id='aboutUs'>
            <div className='container'>
                <div className='content'>
                    <div className='slogan'>
                        <img src='assets/slogan.svg' alt='slogan icon'/>
                        <span>about us</span>
                        <img src='assets/slogan.svg' alt='slogan icon'/>
                    </div>
                    <h3>Who We Are</h3>
                    <p>Netraven, established in 2022, stands out as a
                    premier software agency renowned for its pioneering 
                    and resilient digital solutions in software services.</p>
                    <p>We help you evolve your business into a profitable 
                    venture by using the unrivaled experience of our 
                    highly qualified product development team. </p>
                    <p>Our huge pool of skilled professionals 
                    for Web Development, Mobile Development, UI & UX Designing, Software Q&A Testing and more 
                    help you expand your web and mobile 
                    app development customer base. </p>
                </div>
                <div className='image'>
                    <img src='assets/logo.svg' alt=''/>
                </div>
            </div>
            <div className='container mission-vision-values-container'>
                <div className='box'>
                    <h4>Mission</h4>
                    <p>To empower businesses with cutting-edge software solutions that drive innovation, efficiency, and growth, while exceeding client expectations through unparalleled expertise and dedication.</p>
                </div>
                <div className='box'>
                    <h4>Vision</h4>
                    <p>To be a globally recognized leader in software development, setting new standards of excellence through continuous innovation, exceptional quality, and enduring partnerships.</p>
                </div>
                <div className='box'>
                    <h4>Values</h4>
                    <p>Excellence, collaboration, integrity, innovation, customer-centricity, continuous learning, and social responsibility drive our software agency towards pioneering solutions and societal impact.</p>
                </div>
            </div>
        </section>
    )
}
export default AboutUs;